<template>
    <div class="flex justify-center items-center h-screen bg-gray-200 px-6">
		<div class="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
			<div class="flex flex-col justify-center items-center">
				<h2 class="text-gray-700 font-semibold text-2xl">Verificar dirección de correo</h2>
                <p class="text-gray-500 mb-2 mt-4">Se ha enviado un mensaje de verificación a tu cuenta de correo. </p>
			</div>


            <p class="text-xs text-gray-500 mb-2 mt-4">Si el correo aún no ha llegado puedes volver a enviarlo presionando el botón de aquí abajo. <br></p>
            <BaseBtn
                text="Reenviar Correo"
                @click="sendEmail"
                btnClass="py-2 px-4 mt-2 text-center bg-indigo-600 rounded-md w-full text-white text-sm hover:bg-indigo-500" />
            <p class="text-xs text-gray-500 mb-2 mt-4">O puedes iniciar sesión con otra cuenta<br></p>
            <BaseBtn
                text="Iniciar Sesión"
                @click="changeUser"
                btnClass="py-2 px-4 mt-2 text-center bg-indigo-600 rounded-md w-full text-white text-sm hover:bg-indigo-500" />
		</div>
	</div>
</template>
<script>
import AuthService from '@/services/AuthService'
import BaseBtn from '@/components/BaseBtn'
import store from '@/store'

export default {
    name: "VerifyEmail",
    methods: {
        sendEmail() {
            let email = store.getters["auth/authUser"]["email"]
            AuthService.sendVerification({
                "email": email
            })
        },
        changeUser() {
            store.dispatch("auth/logout")
        }
    },
    components: {
        BaseBtn
    }
}
</script>